<template>
  <div class="content">
    <h1>List</h1>

    <div class="font-bold">
      <span v-if="loadingCount > 0">
        Loading..
      </span>
      <span v-else-if="designs.length === 0">
        No designs saved
      </span>
      <span v-else>
        Showing {{ designs.length }} designs
      </span>
    </div>

    <table class="table table-google mt-3">
      <thead>
        <tr>
          <th></th>
          <th class="text-nowrap">ID</th>
          <th class="text-nowrap">Name</th>
          <th class="text-nowrap">Created</th>
          <th class="text-nowrap">Updated</th>
          <th class="text-nowrap">Wax 1</th>
          <th class="text-nowrap">Wax 2</th>
          <th class="text-nowrap">Wax 3</th>
          <th class="text-nowrap">Frits 1</th>
          <th class="text-nowrap">Frits 2</th>
          <th class="text-nowrap">Template</th>
          <th class="text-nowrap">Frits Decor</th>
          <th class="text-nowrap">Frits Coloring</th>
          <th class="text-nowrap">Frits Palette</th>
          <th class="text-nowrap">Logo</th>
          <th class="text-nowrap">Action</th>
        </tr>
      </thead>
      <draggable
        tag="tbody"
        :list="designs"
        handle=".drag-handle"
        @sort="updateSort"
      >
        <tr v-for="design in designs" :key="design.id">
          <td class="drag-handle">
            <i class="fas fa-bars"></i>
          </td>
          <td>{{ design.id }}</td>
          <td>{{ design.design_name }}</td>
          <td>
            <div class="text-nowrap">
              <div>{{ design.create_user }}</div>
              <div class="text-sm text-gray-600">
                {{ design.create_ts | shortdate }} {{ design.create_ts | time }}
              </div>
            </div>
          </td>
          <td>
            <div class="text-nowrap">
              <div>{{ design.update_user }}</div>
              <div class="text-sm text-gray-600">
                {{ design.update_ts | shortdate }} {{ design.update_ts | time }}
              </div>
            </div>
          </td>
          <td>
            <div class="flex gap-2">
              <mask-display :hex="design.wax_mask1" />
              <button
                v-if="design.wax_mask1_color && design.wax_mask1_color.has_recipe"
                @click="onRecipeOpen(design.wax_mask1_color)"
              >
                <i class="material-icons">list_alt</i>
              </button>
            </div>
          </td>
          <td>
            <div class="flex gap-2">
              <mask-display :hex="design.wax_mask2" />
              <button
                v-if="design.wax_mask2_color && design.wax_mask2_color.has_recipe"
                @click="onRecipeOpen(design.wax_mask2_color)"
              >
                <i class="material-icons">list_alt</i>
              </button>
            </div>
          </td>
          <td>
            <div class="flex gap-2">
              <mask-display :hex="design.wax_mask3" />
              <button
                v-if="design.wax_mask3_color && design.wax_mask3_color.has_recipe"
                @click="onRecipeOpen(design.wax_mask3_color)"
              >
                <i class="material-icons">list_alt</i>
              </button>
            </div>
          </td>
          <td>
            <mask-display :hex="design.stone_mask1" />
          </td>
          <td>
            <mask-display :hex="design.stone_mask2" />
          </td>
          <td>
            {{ design.template }}
          </td>
          <td>
            {{ design.stone_style }}
          </td>
          <td>
            {{ design.stone_coloring }}
          </td>
          <td>
            {{ design.frits_palette }}
          </td>
          <td>
            {{ design.label_style }}
          </td>
          <td class="row-fit">
            <div class="flex gap-4">
              <b-button
                variant="white"
                size="sm"
                :disabled="design.loadingCount > 0"
                @click="renameDesign(design)"
              >
                Rename
              </b-button>

              <router-link
                :to="{ path: 'design', query: { id: design.id }}"
                class="btn btn-primary"
                :is="design.loadingCount > 0 ? 'span' : 'router-link'"
              >
                Open
              </router-link>
              <b-button
                variant="danger"
                size="sm"
                :disabled="design.loadingCount > 0"
                @click="deleteDesign(design)"
              >
                Delete
              </b-button>
            </div>
          </td>
        </tr>
        </draggable>
    </table>

    <recipe-modal
      v-if="recipeModal.showModal"
      :data="recipeModal"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable';

const MaskDisplay = () => import('@/components/designs/MaskDisplay.vue');
const RecipeModal = () => import('@/components/design/RecipeModal.vue');

export default {
  name: 'Designs',
  components: {
    MaskDisplay,
    RecipeModal,
    draggable,
  },
  data() {
    return {
      loadingCount: 0,
      designs: [],
      recipeModal: {
        showModal: false,
        color: null,
      },
    };
  },
  methods: {
    fetchDesigns() {
      this.loadingCount++;
      this.$http
        .get('/design')
        .then((res) => {
          res.body.designs.forEach((design) => {
            design.loadingCount = 0;
          });
          this.designs = res.body.designs;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch designs: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    deleteDesign(design) {
      const shouldDelete = confirm('Do you really wish to delete this design?');
      if (shouldDelete) {
        design.loadingCount++;
        this.$http
          .delete(`/design/${design.id}`)
          .then(() => {
            this.designs = this.designs.filter((filterDesign) => filterDesign.id !== design.id);
          })
          .catch((err) => {
            this.$toast.error(`Failed to delete design: ${err.response.text}`);
          })
          .finally(() => {
            design.loadingCount--;
          });
      }
    },
    renameDesign(design) {
      const designName = prompt('Rename design', design.design_name);
      if (designName) {
        design.loadingCount++;
        this.$http
          .post('/design')
          .send({
            design: {
              ...design,
              design_name: designName,
            },
          })
          .then((res) => {
            Object.keys(res.body.design).forEach((key) => {
              design[key] = res.body.design[key];
            });
          })
          .catch((err) => {
            this.$toast.error(`Failed to rename design: ${err.response.text}`);
          })
          .finally(() => {
            design.loadingCount--;
          });
      }
    },
    updateSort() {
      const designs = this.designs.map((design, index) => ({
        id: design.id,
        list_order: index,
      }));
      this.$http
        .post('/design_sort')
        .send({ designs })
        .then(() => {
          // no-op
        })
        .catch((err) => {
          this.$toast.error(`Failed to sort designs: ${err.response.text}`);
        });
    },
    onRecipeOpen(color) {
      this.recipeModal.color = color;
      this.recipeModal.showModal = true;
    },
  },
  created() {
    this.fetchDesigns();
  },
};
</script>

<style lang="scss" scoped>
</style>
